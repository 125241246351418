


require("blockmapsfonts");

require("@netprofit/lightbox");

require("./referenzenslider.js");

require("./slider.js");

require("./mobile.js");

require("./scroll.js");

require("./maps.js");



const AOS = require("aos");

AOS.init({
    duration: 600,
    easing: 'ease-out-sine',
    startEvent: 'load'
});

document.querySelectorAll("img").forEach(img => {
    img.addEventListener("load", () => {
        AOS.refresh();
    })
})





