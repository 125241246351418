


var element = document.getElementsByClassName('top');


window.addEventListener("scroll", function () {
  checkScrollPosition();
}, {passive: true});

function checkScrollPosition(){
  if(window.scrollY > 250){
    element[0].classList.add('scrolled');
  }else if (window.scrollY < 100) {
    element[0].classList.remove('scrolled');
  }
}
checkScrollPosition();
