

const navView = document.querySelector('.nav-view');
const toggleMenu = document.querySelector('.toggle-menu');

toggleMenu.addEventListener('click', () => {
  navView.classList.toggle('active');
  toggleMenu.classList.toggle('close');
});



const subNav = document.querySelectorAll('.nav-view .nav-item.submenu');

for(var i = 0; i <= subNav.length - 1; i++){
  subNav[i].innerHTML += '<div class="nav-click"></div>';
}

const toggleSubmenu = document.querySelector('.nav-click');

toggleSubmenu.addEventListener('click', function(){

  toggleSubmenu.parentElement.classList.toggle('on');
})


const activeMenu = document.querySelectorAll('.nav-submenu-item.active');

for(var i = 0; i <= activeMenu.length -1; i++){
  activeMenu[i].parentElement.parentElement.classList.add('active');
}
