
const sliderImages = document.querySelectorAll('.sliderimg');

var currentIndex = 0;
var interval = 4000;


// Wenn nur eine Slide is dann nicht Sliden
if (sliderImages.length > 1){
    var sliderTimer = setInterval(slider, interval);
}


function slider(){
    changeSlide(currentIndex);
}



const counterElements = document.querySelectorAll('.slider-count');

counterElements.forEach(function(counterElement) {
    counterElement.addEventListener('click', function() {
        const dataId = counterElement.getAttribute('data-id');

        clearInterval(sliderTimer);
        
        // Slide manuell switchen
        currentIndex = parseInt(dataId) - 1;
        changeSlide(currentIndex);

        
        sliderTimer = setInterval(slider, interval);
          

          
    });
});







function changeSlide(slideid){

    // Counter entfernen
    const allCounter = document.querySelectorAll('.slider-count');
    allCounter.forEach(function(counter){
        counter.classList.remove('active');
    });
    // Images ausblenden
    const allImages = document.querySelectorAll('.sliderimg');
    allImages.forEach(function(images){
        images.style.opacity = 0;
    });
    // Text ausblenden
    const allText = document.querySelectorAll('.slidertext');
    allText.forEach(function(text){
        text.style.opacity = 0;
        text.style.display = 'none';
    });

    slideid = (slideid + 1) % sliderImages.length;

    // Neues Bild definieren
    const nextImage = document.querySelectorAll('.sliderimg');
    nextImage[slideid].style.opacity = 1;
    // Neuen Counter definieren
    const nextCounter = document.querySelectorAll('.slider-count');
    nextCounter[slideid].classList.add('active');
    // Neuen Text definieren
    const nextText =  document.querySelectorAll('.slidertext');
    nextText[slideid].style.opacity = 1;
    nextText[slideid].style.display = 'block';

    currentIndex = slideid;
   
}