var slideWidth = "";
const referenzenslider = document.querySelector('.refernzen-container');
const prevButton = document.querySelector('.ref-prev');
const nextButton = document.querySelector('.ref-next');
let currentSlide = 0;

function showSlide(slideIndex) {
    slideWidth = referenzenslider.offsetWidth;
    referenzenslider.style.transform = `translateX(-${slideIndex * slideWidth}px)`;
}

if (referenzenslider) {
    prevButton.addEventListener('click', () => {
        currentSlide = currentSlide > 0 ? currentSlide - 1 : referenzenslider.children.length - 1;
        showSlide(currentSlide);
    });

    nextButton.addEventListener('click', () => {
        currentSlide = currentSlide < referenzenslider.children.length - 1 ? currentSlide + 1 : 0;
        showSlide(currentSlide);
    });

    window.addEventListener('resize', function(event) {
        showSlide(currentSlide);
    }, true);

    // Touch-Events
    let touchStartX = 0;
    let touchEndX = 0;

    referenzenslider.addEventListener('touchstart', (event) => {
        touchStartX = event.touches[0].clientX;
    });

    referenzenslider.addEventListener('touchend', (event) => {
        touchEndX = event.changedTouches[0].clientX;
        handleSwipeGesture();
    });

    function handleSwipeGesture() {
        if (touchEndX < touchStartX) {
            vorwaertsGehen();
        } else if (touchEndX > touchStartX) {
            zurueckGehen();
        }
    }
}