var apiKey = "AIzaSyAmz71RDkeWfd5pbSNaOSJf6czVuET7_HE";
var setCookie = true;
var cookieExpire = 2*60*60*1000; // 2 Stunden Gültigkeit

const { getCookie } = require('@netprofit/getcookie');

document.querySelectorAll(".map-container").forEach(mapContainer => {
    var button = mapContainer.querySelector(".load-map");
    button.addEventListener("click", () => {
        var map = mapContainer.querySelector(".map");
        button.parentElement.parentElement.style.display = "none";
        if(setCookie){
            const d = new Date();
            d.setTime(d.getTime() + cookieExpire);
            document.cookie = "maps=true; expires=" + d.toUTCString() + "; path=/";
        }
        var maps = [];
        var gMaps = map.lazyLoadGoogleMaps({
            callback: function (container, googleMap) {
                var position = new google.maps.LatLng(map.dataset.latitude, map.dataset.longitude);
                googleMap.setOptions({
                    zoom: parseInt(map.dataset.zoom),
                    center: position,
                    scrollwheel: 0,
                });
                new google.maps.Marker({
                    position: position,
                    map: googleMap
                });
                googleMap.center = position;
                maps.push(googleMap);
                var center = function () {
                    googleMap.center = googleMap.getCenter();
                };
                google.maps.event.addListener(googleMap, "dragend", center);
                google.maps.event.addListener(googleMap, "zoom_changed", center);
                google.maps.event.addListenerOnce(googleMap, "idle", function () {
                    container.classList.add("is-loaded");
                });
            }
        });
        window.addEventListener("resize", gMaps.debounce(1E3, function () {
            maps.forEach(() => {
                this.setCenter(this.dataset.center);
            });
        }));
    });
})







window.googleMapsScriptLoaded = function () {
    var event = new Event('googleMapsScriptLoaded', {"bubbles": true, "cancelable": false})
    window.dispatchEvent(event);
};

(function (window) {
    var windowHeight = window.innerHeight,
        windowScrollTop = 0,
        debounce = function (delay, fn) {
            var timer = null;
            return function () {
                var context = this, args = arguments;
                clearTimeout(timer);
                timer = setTimeout(function () { fn.apply(context, args); }, delay);
            };
        },
        apiScriptLoaded = false,
        apiScriptLoading = false,
        containers = [],
        init = function (callback) {
            windowScrollTop = window.scrollTop;
            containers.forEach(container => {
                if (container.getBoundingClientRect().top - windowScrollTop > windowHeight * 1)
                    return true;
                if (!apiScriptLoaded && !apiScriptLoading) {
                    var script = document.createElement("script");
                    script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp&callback=googleMapsScriptLoaded&key=' + apiKey;
                    document.body.appendChild(script);
                    apiScriptLoading = true;
                }
                if (!apiScriptLoaded) return true;
                var map = new google.maps.Map(container, {
                    zoom: 15
                });
                if (container.data.callback) {
                    container.data.callback(container, map);
                }
            });
        };
    window.addEventListener('googleMapsScriptLoaded', () => {
        apiScriptLoaded = true;
        init();
    });
    HTMLDivElement.prototype.lazyLoadGoogleMaps = function (options) {
        options = extend({
            api_key: false,
            callback: false,
        }, options);
        containers.push(this);
        this.data = options;
        init();
        this.debounce = debounce;
        return this;
    };
})(window);

function extend(out) {
    out = out || {};
    for (var i = 1; i < arguments.length; i++) {
        if (!arguments[i])
            continue;
        for (var key in arguments[i]) {
            if (arguments[i].hasOwnProperty(key))
                out[key] = arguments[i][key];
        }
    }
    return out;
}



if(setCookie && getCookie("maps") == "true"){
    document.querySelectorAll(".map-container").forEach(container => {
        var button = container.querySelector(".load-map");
        var event = new Event('click', {"bubbles": true, "cancelable": false});
        button.dispatchEvent(event);
    });
}